
// acid license Routes......
const acidLicense = '/acid-license/application-process/'
export const acidLicenseApplicationListApi = acidLicense + 'application-list'
export const acidLicenseApplicationForwardListApi = acidLicense + 'forward-list'
export const designationWiseUser = acidLicense + 'user-list'
export const acidLicenseForwardApi = acidLicense + 'forward'
export const acidLicenseInspectionListApi = acidLicense + 'inspection-list'
export const acidLicenseApprovalListApi = acidLicense + 'approval-list'
export const acidLicenseApprovedListApi = acidLicense + 'approved-list'
export const acidLicenseApprovedSubmitApi = acidLicense + 'approved-submit'
export const acidLicenseRejectedListApi = acidLicense + 'rejected-list'
export const acidLicenseRejectApi = acidLicense + 'reject'
export const acidLicenseInpectionSubmitApi = acidLicense + 'inspection-submit'
