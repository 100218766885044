<template>
    <ValidationObserver ref="form" v-slot="{ handleSubmit, reset }">
      <b-overlay :show="loading">
        <b-form @submit.prevent="handleSubmit(saveUpdate)" @reset.prevent="reset" autocomplete="off"  enctype="multipart/form-data">
          <b-row>
            <!-- inspected_attachment -->
            <b-col xs="6" sm="6" md="6">
              <ValidationProvider name="Inspected Attachment" vid="inspected_attachment" rules="required">
                <b-form-group
                  clabel-for="inspected_attachment"
                  slot-scope="{ valid, errors }"
                  >
                  <template v-slot:label>
                  {{ $t('globalTrans.attachment') }} <span class="text-danger">*</span>
                  </template>
                  <b-form-file
                    id="inspected_attachment"
                    name="inspected_attachment"
                    v-model="formData.inspected_attachment"
                    v-on:change="onChange"
                    :state="errors[0] ? false : (valid ? true : null)"
                  ></b-form-file>
                  <div class="invalid-feedback">
                  {{ errors[0] }}
                  </div>
                </b-form-group>
              </ValidationProvider>
            </b-col>
            <b-col></b-col>
            <!-- note_en -->
            <b-col xs="6" sm="6" md="6">
              <ValidationProvider name="Note (En)" vid="inspected_note" rules="required">
                <b-form-group
                  label-for="inspected_note"
                  slot-scope="{ valid, errors }"
                  >
                  <template v-slot:label>
                    {{ $t('license_management.note_bn') }}<span class="text-danger">*</span>
                  </template>
                  <b-form-textarea
                    id="inspected_note"
                    v-model="formData.inspected_note"
                    rows="5"
                    :state="errors[0] ? false : (valid ? true : null)"
                  ></b-form-textarea>
                  <div class="invalid-feedback">
                    {{ errors[0] }}
                  </div>
                </b-form-group>
              </ValidationProvider>
            </b-col>
            <!-- note bn -->
            <b-col xs="6" sm="6" md="6">
              <ValidationProvider name="Note (Bn)" vid="inspected_note_bn" rules="required">
                <b-form-group
                  label-for="inspected_note_bn"
                  slot-scope="{ valid, errors }"
                  >
                  <template v-slot:label>
                    {{ $t('license_management.note_en') }}<span class="text-danger">*</span>
                  </template>
                  <b-form-textarea
                    id="inspected_note_bn"
                    v-model="formData.inspected_note_bn"
                    rows="5"
                    :state="errors[0] ? false : (valid ? true : null)"
                  ></b-form-textarea>
                  <div class="invalid-feedback">
                    {{ errors[0] }}
                  </div>
                </b-form-group>
              </ValidationProvider>
            </b-col>
          </b-row>
          <b-row class="text-right">
            <b-col>
                <b-button type="submit" variant="success" class="mr-2 btn-sm">{{ saveBtnName }}</b-button>
                <b-button variant="danger" class="btn-sm" @click="$bvModal.hide('inspection_modal')">{{ $t('globalTrans.cancel') }}</b-button>
            </b-col>
          </b-row>
        </b-form>
      </b-overlay>
    </ValidationObserver>
  </template>
  <script>
  import RestApi, { lrcpnServiceBaseUrl } from '@/config/api_config'
  import { acidLicenseInpectionSubmitApi } from '../api/routes'
  export default {
    name: 'Form',
    props: ['id'],
    data () {
      return {
        valid: null,
        saveBtnName: this.$t('globalTrans.save'),
        errors: [],
        formData: {
          inspected_note: '',
          inspected_note_bn: ''
        }
      }
    },
    created () {
    },
    computed: {
      options () {
        const datas = this.$store.state.LicenseRegistrationService.commonObj.actionTypeList
        return datas.map(item => {
          if (this.$i18n.locale === 'bn') {
            return { text: item.text_bn, value: item.value }
          } else {
            return { text: item.text_en, value: item.value }
          }
        })
      },
      loading: function () {
        return this.$store.state.commonObj.loading
      }
    },
    methods: {
      onChange (e) {
        const selectedFile = e.target.files[0]
        const reader = new FileReader()
        reader.onload = (event) => {
          this.formData.inspected_attachment = event.target.result
        }
        reader.readAsDataURL(selectedFile)
      },
      async saveUpdate () {
        this.$store.dispatch('mutateCommonProperties', { loading: true })
        let result = null
        const storeData = Object.assign(this.formData, { app_id: this.id })
        const loadingState = { loading: false, listReload: false }
        result = await RestApi.postData(lrcpnServiceBaseUrl, acidLicenseInpectionSubmitApi, storeData)

        loadingState.listReload = true
        this.$store.dispatch('mutateCommonProperties', loadingState)

        if (result.success) {
          this.$store.dispatch('LicenseRegistrationService/mutateCommonObj', { hasDropdownLoaded: false })
          this.$toast.success({
            title: this.$t('globalTrans.success'),
            message: this.$t('globalTrans.save_msg'),
            color: '#D6E09B'
          })

          this.$bvModal.hide('inspection_modal')
        } else {
          this.$refs.form.setErrors(result.errors)
        }
      }
    }
  }
  </script>
